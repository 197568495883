import React, { useEffect, useState } from 'react'
import "./css/PopupAlert.scss";
import { IoClose } from "react-icons/io5";

const PopupAlert = () => {
    let [toogle, setToogle] = useState(true)
    useEffect(() => {

    }, [toogle])
    return (
        <div className='popupAlertContainer' style={{ display: toogle ? 'flex' : "none" }}>
            <div className='textContainer'>
                <span className='closeBtn' onClick={() => setToogle(false)}><IoClose /></span>
                <img src="/assets/logo.png" />
                <p>Our office will be closed from 23rd December 2024 to 5th January 2025 on account of Christmas and the New Year.</p>
                <p>We wish you all a joyful holiday season filled with happiness and warmth. Thank you for your continued support, and we look forward to serving you in 2025!</p>
                <p className='title'>Happy New Year</p>

            </div>
        </div>
    )
}

export default PopupAlert